import config from "../config"

import React, { Component } from "react";
import { 
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Glyphicon,
  ProgressBar,
  Modal
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import * as api from "./api";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import "react-datepicker/dist/react-datepicker.css";

import "./ItemEdit.css";


/*
{
  "id": "04dc16c6-3058-4b99-92ff-6500444b9d44",
  "type": "podcast",
  "author": "Tim Locke",
  "description": "Faith Working Through Love: Gospel Distortions",
  "enclosure_link": "sermons/2018_10_14.mp3",
  "enclosure_type": "audio/mpeg",
  "link": "http://www.eastcobbpres.org",
  "title": "Faith Working Through Love: Gospel Distortions"
  "subtitle": "Tim Locke. Sunday 10/14/18",
  "summary": "Faith Working Through Love: Gospel Distortions",
  "timestamp": 1539493200,

}
*/

const baseStyle = {
  width: "100%",
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5
};
const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};
const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

const humanFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
};

const getExtension = (filename) => {
  return filename.substring(filename.lastIndexOf('.')+1);
}

const getMimeType = (filename) => {
  const mimetypes = {
    'mp3': 'audio/mpeg',
    'mp4': 'audio/mp4',
    'ogg': 'audio/ogg'
  };
  const extension = getExtension(filename);
  if (mimetypes[extension]) {
    return mimetypes[extension];
  }
  return config.DEFAULT_PODCAST_MIMETYPE;
};

const headFileSize = async (url) => {
  try {
    console.log("headFileSize url:", url);
    const response = await fetch(url, {
      method: "HEAD"
    });
    if (response.status !== 200) {
      return null;
    }
    console.log("headFileSize response.status", response.status);
    const len = response.headers.get("content-length");
    console.log("headFileSize len:", len);
    if (!len) {
      return null;
    }
    return parseInt(len);
  } catch(e) {
    console.log("exception in headFileSize", e);
    return null;
  }
};


export default class ItemEdit extends Component {
  constructor(props) {
    super(props);
    let timestamp = (new Date().getTime()) / 1000;
    this.state = {
      isLoading: true,
      showUploadDragDrop: false,
      author: "",
      description: "",
      link: config.DEFAULT_PODCAST_LINK,
      title: "",
      subtitle: "",
      summary: "",
      timestamp: timestamp,
      itemdate: new Date(timestamp * 1000),
      file_to_upload: false,
      upload_current: 0,
      upload_size: 0,
      upload_percent: 0,
      uploading: false,
      showCreateItemModal: false,
      showFinishedModal: false,
      showDeleteConfirmation: false
    };
  }

  async componentDidMount() {
    // if we were passed an item in props, use that
    // otherwise fetch it from id
    let item = null;
    const itemId = this.props.match.params.id;
    if (itemId) {
      // got an itemId, grab the item and fill in our state
      console.log(`itemId: ${itemId}`);
      item = await api.getItem(itemId);
      console.log("item", item);

      let audio_length = null;
      if (item.enclosure_link) {
        // get the resulting length of the file
        // get just the filename of the link
        const audioFilename = item.enclosure_link.substring(item.enclosure_link.lastIndexOf('/')+1);
        audio_length = await headFileSize(`${config.SERMONS_BASE_URL}${audioFilename}`);
        console.log("audio_length", audio_length);
      }
      // set the state from this item
      this.setState({
        isLoading: false,
        showUploadDragDrop: (!item.enclosure_link || item.enclosure_link.length === 0),
        author: item.author,
        description: item.description,
        link: item.link,
        title: item.title,
        subtitle: item.subtitle,
        summary: item.summary,
        timestamp: item.timestamp,
        itemdate: new Date(item.timestamp * 1000),
        audio_file_name: item.enclosure_link,
        audio_file_len: audio_length,
        itemId: itemId
      });
    } else {
      console.log("no item was specified, treating as add");
      this.setState({
        isLoading: false,
        showUploadDragDrop: true
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleDateChange = (newDate) => {
    console.log("handleDateChange", newDate);
    this.setState({
      itemdate: newDate,
      timestamp: Math.round(newDate.getTime() / 1000)
    });
  }

  toggleUploadDragDrop = event => {
    this.setState({
      showUploadDragDrop: !this.state.showUploadDragDrop
    });
  }

  onDropzoneDrop = async (files) => {
    console.log("onDropzoneDrop files", files);

    // get the signed upload url
    if (files.length !== 1) {
      return;
    }

    // save the file in the state
    const uploadFile = files[0];
    this.setState({
      file_to_upload: uploadFile,
      audio_file_name: uploadFile.name,
      audio_file_len: uploadFile.size,
      showUploadDragDrop: false
    });

    console.log(`uploadFile: name: ${uploadFile.name}, 
      type: ${uploadFile.type}, 
      name: ${uploadFile.name},
      size: ${uploadFile.size}`);
  }

  onDropzoneCancel = () => {
    console.log("onDropzoneCancel");
    this.setState({
      file_to_upload: null
    });
  }

  getAudioFileURL = () => {
    const filename = this.state.audio_file_name.substring(this.state.audio_file_name.lastIndexOf('/')+1);
    return `${config.SERMONS_BASE_URL}${filename}`;
  }

  uploadFile = async () => {
    // get the upload URL
    const upload_file = this.state.file_to_upload;
    // make sure the filename is YYYY_MM_DD.mp3
    //const date_filename = api.getDateFilename() + '.' + getExtension(upload_file);
    const resp = await api.getUploadUrl(upload_file.name, upload_file.type);
    console.log("api.getUploadUrl", resp);

    // set state to uploading
    this.setState({
      upload_current: 0,
      upload_size: upload_file.size,
      upload_percent: 0,
      uploading: true,
    });

    // start the actual upload and get progress
    const resp2 = await api.uploadS3(resp.url, resp.key, upload_file, upload_file.type, (event) => {
      console.log("onProgress", event);
      const total_uploaded = event.loaded;
      const upload_percent = parseFloat( ((total_uploaded / this.state.upload_size) * 100).toFixed(0) );
      this.setState({
        upload_current: total_uploaded,
        upload_percent: upload_percent,
      });
    });

    // finished uploading
    console.log("upload response:", resp2);

    // get the resulting length of the file
    const headURL = this.getAudioFileURL();
    console.log(`getting size via HEAD of ${headURL}`);
    const fileLen = await headFileSize(headURL);
    console.log("fileLen", fileLen);
    if (fileLen) {
      this.setState({
        uploading: false
      });
    }
  }

  handleCloseCreateItemModal = async () => {
    console.log("handleCloseCreateItemModal");
  }

  handleCloseFinishedModal = async () => {
    this.setState({
      showFinishedModal: false,
      redirectToList: true
    });
  }

  validateForm() {
    const allTextFieldsReady = 
      this.state.author && this.state.author.length > 0 &&
      this.state.description && this.state.description.length > 0 &&
      this.state.link && this.state.link.length > 0 &&
      this.state.title && this.state.title.length > 0 &&
      this.state.subtitle && this.state.subtitle.length > 0 &&
      this.state.summary && this.state.summary.length > 0 &&
      this.state.timestamp && this.state.timestamp !== 0;

    const audioFileReady =
      this.state.audio_file_name && this.state.audio_file_name.length > 0;

    return allTextFieldsReady && audioFileReady;
  }

  handleSubmit = async event => {
    event.preventDefault();
    console.log("handleSubmit", event);

    // build the item to save
    let newItem = {
      author: this.state.author,
      description: this.state.description,
      link: this.state.link,
      title: this.state.title,
      subtitle: this.state.subtitle,
      summary: this.state.summary,
      timestamp: this.state.timestamp,
      enclosure_link: this.state.audio_file_name,
      enclosure_type: getMimeType(this.state.audio_file_name)
    };

    // show the creating item modal
    this.setState({showCreateItemModal: true});

    // is there an item to upload? if so do that first
    if (this.state.file_to_upload) {
      await this.uploadFile();
    }

    // save the item
    this.setState({saving_item: true});
    if (this.state.itemId) {
      const resp = await api.editItem(this.state.itemId, newItem);
      console.log("api.editItem response", resp);
    } else {
      const resp = await api.addItem(newItem);
      console.log("api.addItem response", resp);
    }

    this.setState({
      showCreateItemModal: false,
      showFinishedModal: true
    });
  }

  handleDeleteClicked = async (event) => {
    console.log('handleDeleteClicked');
    this.setState({showDeleteConfirmation: true});
  }
 
  handleCloseDeleteConfirmModal = async () => {
    this.setState({showDeleteConfirmation: false});
  }

  onDeleteConfirm = async () => {
    // perform the delete
    console.log('onDeleteConfirm');
    const res = await api.deleteItem(this.state.itemId);
    console.log("deleteItem result", res);
    this.setState({
      showDeleteConfirmation: false,
      redirectToList: true
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <h4>Loading...</h4>
      );
    }
    const hasFile = (this.state.audio_file_name && this.state.audio_file_name.length > 0);
    return (
      <div className="ItemEdit">
        { this.state.itemId ? 
          (<h3>Editing Item <code>{this.state.itemId}</code></h3>) : 
          (<h3>Add new item</h3>)
        }

        <form onSubmit={this.handleSubmit}>

          { !this.state.uploading && 
            <FormGroup controlId="fileupload">
              <ControlLabel>Audio File</ControlLabel>
              { hasFile && 
                <div>
                  <p>
                    <audio controls>
                      <source src={this.getAudioFileURL()} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </p>
                  <p>
                    <span className="audioFileInfoItem">
                      <strong>Filename:</strong>&nbsp;
                      <code>{this.state.audio_file_name}</code>
                    </span>
                    { this.state.audio_file_len && 
                      <span className="audioFileInfoItem">
                        <strong>Size:</strong>
                        <code>{humanFileSize(this.state.audio_file_len, true)}</code>
                      </span>
                    }
                    <Button bsSize="small" onClick={this.toggleUploadDragDrop}>
                      <Glyphicon 
                        glyph={this.state.showUploadDragDrop ? "chevron-up" : "chevron-down"}
                      />&nbsp;
                      Select New File
                    </Button>
                  </p>
                </div>
              }
              {/* accept only mp3 files for upload */}
              { this.state.showUploadDragDrop && 
                <Dropzone 
                  accept="audio/mp3, audio/mpeg"
                  onDrop={this.onDropzoneDrop}
                  onFileDialogCancel={this.onDropzoneCancel}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                    let styles = {...baseStyle}
                    styles = isDragActive ? {...styles, ...activeStyle} : styles
                    styles = isDragReject ? {...styles, ...rejectStyle} : styles

                    return (
                      <div
                        {...getRootProps()}
                        style={styles}
                      >
                        <input {...getInputProps()} />
                        <div className="dragMessageContainer">
                        <div className="dragMessageInner">
                          <Glyphicon className="uploadIcon" glyph="cloud-upload" /><br/>
                          {isDragAccept ? 'Drop' : 'Click here or drag'} { hasFile && 'new'} mp3 file here...
                        </div>
                        </div>
                        {isDragReject && <div>Unsupported file type...</div>}
                      </div>
                    )
                  }}
                </Dropzone>
              }
            </FormGroup>
          }

          <FormGroup controlId="author">
            <ControlLabel>Author</ControlLabel>
            <FormControl
              value={this.state.author}
              onChange={this.handleChange}
              type="text"
              autoFocus
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Date</ControlLabel>
            <br />
            <DatePicker controlId="itemdate"
              selected={this.state.itemdate}
              onChange={this.handleDateChange}
              className="form-control"
            />
          </FormGroup>

          <FormGroup controlId="title">
            <ControlLabel>Sermon Title</ControlLabel>
            <FormControl
              type="text"
              value={this.state.title}
              onChange={this.handleChange}
            />
          </FormGroup>

          <FormGroup controlId="subtitle">
            <ControlLabel>Series Title (Subtitle)</ControlLabel>
            <FormControl
              value={this.state.subtitle}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>

          <FormGroup controlId="summary">
            <ControlLabel>Summary</ControlLabel>
            <FormControl
              value={this.state.summary}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>

          <FormGroup controlId="link">
            <ControlLabel>Link</ControlLabel>
            <FormControl
              value={this.state.link}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>

          <FormGroup controlId="description">
            <ControlLabel>Description</ControlLabel>
            <FormControl
              componentClass="textarea"
              placeholder="Description goes here..."
              value={this.state.description}
              onChange={this.handleChange}
            />
          </FormGroup>

          <div className="buttonDiv">
            <Button
              bsStyle="primary"
              disabled={!this.validateForm()}
              type="submit"
            >
              {this.state.itemId ? "Save Item" : "Add Item"}
            </Button>

            {/* show the delete button if this is an existing item with an id */}
            { this.state.itemId &&
              <Button 
                bsStyle="danger"
                onClick={this.handleDeleteClicked}
              >
                Delete Item
              </Button>
            }
          </div>

        </form>

        {/* The create item modal */}
        <Modal 
          show={this.state.showCreateItemModal}
          onHide={this.handleCloseCreateItemModal}
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Creating new item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="createItemModalDiv">
              { this.state.uploading &&
                <div>
                  <p>
                    Uploading&nbsp;
                    <code>{this.state.audio_file_name}</code>&nbsp;
                    <code>{humanFileSize(this.state.upload_current, true)}</code>&nbsp;of&nbsp;
                    <code>{humanFileSize(this.state.upload_size, true)}</code>
                  </p>
                  <ProgressBar
                    label={`${this.state.upload_percent}%`}
                    now={this.state.upload_percent}
                  />
                </div>
              }
              { this.state.saving_item && 
                <div className="savingItemDiv">
                  <p>Saving item...</p>
                  <ReactLoading type="spin" color="black" height={64} width={64} />
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>

        {/* The finished saving modal -- just a nice notification for the user */}
        <Modal show={this.state.showFinishedModal} onHide={this.handleCloseFinishedModal}>
          <Modal.Header closeButton>
            <Modal.Title>Done</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                <Glyphicon glyph="check" className="finishedCheck" />
                <span className="finishedParagraph">Item has been saved</span>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseFinishedModal}>OK</Button>
          </Modal.Footer>
        </Modal>

        {/* delete item confirmation */}
        <Modal 
          show={this.state.showDeleteConfirmation}
          onHide={this.handleCloseDeleteConfirmModal}
        >
          <Modal.Header>
            <Modal.Title>Really Delete Item?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this item?
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={this.handleCloseDeleteConfirmModal}>
              Cancel
            </Button>
            <Button bsStyle="danger" onClick={this.onDeleteConfirm}>
              Yes, Delete It
            </Button>
          </Modal.Footer>
        </Modal>

        {/* redirect to the list page */}
        { this.state.redirectToList && 
          <Redirect push to='/list' />
        }

      </div>
    );
  }
}