import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import * as api from "./containers/api"
import "./App.css";

import mainLogo from './logo_small.png';


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    let hasAuthed = false;
    try {
      console.log("trying to check if already logged in..");
      hasAuthed = await api.isLoggedIn();
    }
    catch(e) {
      console.log("caught exception while trying to call api.isLoggedIn", e);
    }

    console.log(`hasAuthed: ${hasAuthed}`);
    this.userHasAuthenticated(hasAuthed);
    this.setState({ isAuthenticating: false });

    if (!hasAuthed) {
      console.log("user is not authenticated, forcing logout");
      this.handleLogout();
    }
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await api.logout();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  render() {
    console.log("App.render()");

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    const defaultLink = this.state.isAuthenticated ? '/' : '/login';

    return (
      !this.state.isAuthenticating &&
      <Fragment>
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Link to={defaultLink}><img src={mainLogo} className="headerLogo" alt={'ECPC Media'} /></Link>
            <Navbar.Brand>
              <Link to={defaultLink}>ECPC Media</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {this.state.isAuthenticated
                ? <Fragment>
                    <NavItem eventKey={1} href="/add">
                      <Glyphicon glyph="plus-sign" />&nbsp;Add
                    </NavItem>
                    <NavItem eventKey={2} href="/list">
                      <Glyphicon glyph="th-list" />&nbsp;List
                    </NavItem>
                    <NavItem onClick={this.handleLogout}>
                      <Glyphicon glyph="off" />&nbsp;Logout
                    </NavItem>
                  </Fragment>
                : 
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="App container">
          <Routes childProps={childProps} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(App);