import config from "../config"

/*
 * api routines
 */

const saveToken = (token) => {
  localStorage.setItem('ecpcApiToken', token);
}

const getToken = () => {
  return localStorage.getItem('ecpcApiToken');
}

const clearToken = () => {
  localStorage.removeItem('ecpcApiToken');
}

export const login = async (user, pass) => {
  const rawResponse = await fetch(config.API_URL + 'login', { 
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({username: user, password: pass})
  });
  const content = await rawResponse.json();
  console.log(content);
  if (content.success && content.token) {
    saveToken(content.token);
    return content.token;
  }
  // login failed
  let msg = "Login failed";
  if (content.status) {
    msg += '. ' + content.status;
  }
  throw new Error(msg);
};

export const isLoggedIn = async () => {
  const token = getToken();
  if (!token) {
    // if no token then obviously not logged in
    console.log("no token, obviously not logged in");
    return false;
  }
  // we have a token, so see if its still valid
  const rawResponse = await fetch(config.API_URL + 'podcast_item/none', {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
  const content = await rawResponse.json();
  console.log(content);
  if (content.success) {
    // token is still valid
    console.log("token is still valid");
    return true;
  }

  // token is not valid anymore
  clearToken();
  console.log("token is no longer valid");
  return false;
};

export const logout = async () => {
  console.log("logging out");
  clearToken();
  console.log("token: " + getToken());
  return true;
}

export const getItems = async () => {
  // get the list of items
  const rawResponse = await fetch(config.API_URL + 'podcast_item/all', {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  const content = await rawResponse.json();
  return content;
}

export const getItem = async (id) => {  
  // get a single item
  const rawResponse = await fetch(config.API_URL + `podcast_item/${id}`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  const content = await rawResponse.json();
  return content;
}

export const editItem = async (id, newItem) => {  
  // edit an item
  const rawResponse = await fetch(config.API_URL + `podcast_item/${id}`, {
    method: 'put',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(newItem)
  });
  const content = await rawResponse.json();
  return content;
}

export const addItem = async (newItem) => {  
  // add an item
  const rawResponse = await fetch(config.API_URL + 'podcast_item', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(newItem)
  });
  const content = await rawResponse.json();
  return content;
}

export const deleteItem = async (id) => {
  // get a single item
  const rawResponse = await fetch(config.API_URL + `podcast_item/${id}`, {
    method: 'delete',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  const content = await rawResponse.json();
  return content;
}

export const getUploadUrl = async (filename, filetype) => {
  const rawResponse = await fetch(config.API_URL + 'podcast_upload_url', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify({filename: filename, filetype: filetype})
  });
  const content = await rawResponse.json();
  return content;  
}

export const uploadS3 = (signed_url, upload_key, file, filetype, onProgress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(signed_url);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        }
      }
    }

    console.log("xhr upload_key", upload_key);
    console.log("xhr filetype", filetype);

    xhr.open('PUT', signed_url);
    xhr.setRequestHeader('Content-Type', filetype);
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    }
    // XXX: fix the send to actually send the body of the file :-/
    xhr.send(file);
    /*
    xhr.send({
      //uri: file.uri,
      type: filetype,
      name: upload_key,
    });
    */
  })
}

export const getDateFilename = (date) => {
  date = date || new Date();
   
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('_');
}
