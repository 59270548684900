import React, { Component } from "react";
import { Glyphicon, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import * as api from "./api";
import "./ItemList.css";

export default class ItemList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  async componentDidMount() {
    const items = await api.getItems();
    console.log(`fetched ${items.length} items`);
    this.setState({
      items: items
    });
  }

  dateFormatter(cell, row) {
    return ( 
      <span>
        { new Date(row.timestamp*1000).toLocaleDateString() }
      </span>
    );
  }

  editFormatter(cell, row) {
    return (
      <LinkContainer to={`/edit/${row.id}`}>
        <Button>
          <Glyphicon glyph="pencil" />
          &nbsp;Edit
        </Button>
      </LinkContainer>
    )
  }

  enclosureFormatter(cell, row) {
    const hasFile = (row.enclosure_link && row.enclosure_link.length > 0);
    return (
      <span
        style={{color: (hasFile ? "green" : "red")}}
      >
      { hasFile ?
          <Glyphicon glyph="check" /> :
          <span><Glyphicon glyph="info-sign" />&nbsp;Missing</span>
      }
      </span>
    )
  }

  render() {
    const columns = [
      {
        dataField: 'edit',
        isDummyField: true,
        text: '',
        formatter: this.editFormatter
      },
      {
        dataField: 'timestamp',
        text: 'Date',
        formatter: this.dateFormatter
      },
      {
        dataField: 'author',
        text: 'Author'
      },
      {
        dataField: 'title',
        text: 'Title'
      },
      /*
      {
        dataField: 'subtitle',
        text: 'Subtitle'
      }
      */
      {
        dataField: 'enclosure_link',
        text: 'File',
        formatter: this.enclosureFormatter
      }
    ];

    return (
      <BootstrapTable
        keyField="id"
        data={ this.state.items }
        columns={ columns }
        bordered={ false }
        striped
        hover
        condensed
      />
    )
  }
}
