import React from "react";
import { Route, Switch } from "react-router-dom";
import ItemList from "./containers/ItemList";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import ItemEdit from "./containers/ItemEdit";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={ItemEdit} props={childProps} />
    <AppliedRoute path="/list" exact component={ItemList} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/edit/:id" component={ItemEdit} props={childProps} />
    <AppliedRoute path="/add" component={ItemEdit} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;